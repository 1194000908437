import {Form, useActionData} from "@remix-run/react";
import isEmpty from "lodash/isEmpty";
import {forwardRef, ReactNode} from "react";
import {FieldValues, FormProps, useFormContext} from "react-hook-form";
import {Box} from "~/component/Box";
import {Alert} from "~/component/ui/alert";
import {RemixFormProvider} from "remix-hook-form";

interface FormViewProps<TFieldValues extends FieldValues> extends FormProps<TFieldValues> {
  children: ReactNode;
  submitText?: ReactNode;
  fixedSubmit?: boolean;
  centeredSubmit?: boolean;
  fullWidthSubmit?: boolean;
}

function DebugForm() {
  const { formState, getValues } = useFormContext();

  if (isEmpty(formState.errors)) {
    return null;
  }

  return (
    <Box>
      <Box component="pre" className={"whitespace-pre-wrap"}>
        {JSON.stringify(getValues(), null, 2)}
      </Box>
      <Box component="pre" className={"whitespace-pre-wrap"}>
        {JSON.stringify(formState.errors, null, 2)}
      </Box>
    </Box>
  );
}

function GlobalFormError({ actionData }: { actionData: any }) {
  const name = "global";

  const error = actionData?.fieldErrors && actionData?.fieldErrors[name];

  if (!error) {
    return null;
  }

  return (
    <Box className={"my-2"} id={`${name}-form-error`}>
      <Alert variant="destructive">{error}</Alert>
    </Box>
  );
}

export default forwardRef(function FormView<DataType>(
  {
    children,
    form,
    ...props
  }: FormViewProps<DataType>, ref) {
  const actionData = useActionData();

  return (
    <RemixFormProvider {...form}>
      <Form
        method="POST"
        ref={ref}
        // Why are my fields triggering the native HTML validations before remix-validated-form ones?
        // This is happening because you or the library you are using is passing the required attribute
        //  to the fields. This library doesn't take care of eliminating them and it's up to the user
        // how they want to manage the validation errors. If you wan't to disable all native HTML
        // validations you can add noValidate to <ValidatedForm>. We recommend this approach since
        // the validation will still work even if JS is disabled.
        noValidate
        onSubmit={form.handleSubmit}
        {...props}
      >
        {process.env.NODE_ENV === "development" && <DebugForm />}

        {children}

        <GlobalFormError actionData={actionData} />
      </Form>
    </RemixFormProvider>
  );
});